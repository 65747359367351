.rbc-event {
  background-color: #357edd;
  border-radius: 4px;
  color: white;
  padding: 2px;
  text-align: center;
}

.rbc-today {
  background-color: #f5f5f5;
}

.rbc-time-view,
.rbc-time-content {
  border: none;
  border-top: 1px solid #e4e7ec;
}

.rbc-time-view {
  .rbc-label {
    display: none;
  }
  .rbc-allday-cell {
    border-bottom: 1px solid #e4e7ec;
    border-right: 1px solid #e4e7ec;
    padding-top: 20px;
  }
  .rbc-time-content {
    display: none;
  }
}

.rbc-time-view .rbc-row {
  min-height: 40px;
}

.rbc-header {
  border-bottom: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.rbc-time-header-content {
  border-right: 1px solid #e4e7ec;
}

.rbc-button-link {
  cursor: auto;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
}

.rbc-row-segment {
  padding: 0px 5px;
}

.rbc-month-view {
  border-radius: 20px !important;
}

.columnheader {
  text-transform: capitalize;
}

.rbc-event {
  background-color: transparent !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-button-link {
  user-select: none;
}
