.hide-scrollbar {
  overflow-y: auto; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-width: none; 
}

.hide-scrollbar {
  -ms-overflow-style: none;
}

.no-select {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}
