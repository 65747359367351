.quill {
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: #d0d5dd;
  }
  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: #d0d5dd;
    font-family: "Open Sans";
  }
}
.ql-editor {
  font-size: 16px; 
}

.ql-editor::before {
  font-size: 16px;
  font-style: normal !important; 
  color: #c4c4c4 !important;
}
